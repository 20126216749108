import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { Notification } from '../interfaces/notification.interface';
import { trackById } from '@vex/utils/track-by';
import { VexDateFormatRelativePipe } from '@vex/pipes/vex-date-format-relative/vex-date-format-relative.pipe';
import { Router, RouterLink } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { NgClass, NgFor } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { GlobalService } from '../../../../../shared/services/global.service';
import { AuthService } from '../../../../../shared/services/auth.service';
import { VexPopoverService } from '@vex/components/vex-popover/vex-popover.service';
import { NotifyService } from '../../../../../shared/services/notify.service';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    NgFor,
    MatRippleModule,
    RouterLink,
    NgClass,
    VexDateFormatRelativePipe
  ],
  providers: [GlobalService, AuthService]
})
export class ToolbarNotificationsDropdownComponent implements OnInit {
  notifications: Notification[];
  notificationCount: number = 0;
  selectedData: any;
  originRef?: ElementRef;

  trackById = trackById;
  private dropdownOpen: boolean;

  constructor(
              private router: Router,
              private notificationService: NotificationService,
              private notifyService: NotifyService,
              private popover: VexPopoverService,
              private cd: ChangeDetectorRef,) {
  }

  ngOnInit() {
    this.refresh();
  }

  refresh(): void {
    this.get();
  }
  get(): void {
    this.notifyService.unreadNotification().subscribe(data => {
      if(data && data['data'].length > 0) {
        this.notificationCount = data['data'].length;
        let notification: any = [];
        data['data'].forEach((value: any): void => {
          const date: any = new Date();
          const created_date: any = new Date(value.created_at);
          const differenceHours: number = Math.abs(date - created_date) / (1000 * 60 * 60);
          notification.push({
            id: value.id,
            label: value.notification,
            icon: 'mat:feedback',
            colorClass: 'text-primary-600',
            datetime: DateTime.local().minus({ hour: differenceHours }),
            read: false
          });
        });
        this.notifications = notification;
      }
    });
  }
  readNotification(data: any): void {
    data.read = true;
  }

  readAllNotification(): void{
    if(this.notificationCount > 0){
      this.notifyService.readNotification().subscribe(data => {
        this.notificationCount = 0;
        this.notificationService.shareData("0");
      });
    }
  }
  goto(url: any): void {
    this.router.navigateByUrl(url);
  }
}

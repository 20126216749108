import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[numbersOnly]'
})
export class NumberOnlyDirective {
  constructor() {
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    const charCode = (event.which) ? event.which : event.keyCode;
    const inputElement = event.target as HTMLInputElement;
    const currentValue = inputElement.value;

    // Allow only one decimal point
    if (charCode === 46 || charCode === 45) {
      if (currentValue.includes('.')) {
        event.preventDefault();
        return false;
      }
      return true;
    }

    // Allow numbers
    if (charCode >= 48 && charCode <= 57) {
      return true;
    }

    event.preventDefault();
    return false;
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData('text');

    // Allow numbers and one decimal point
    const regex = /^\d*\.?\d*$/;
    if (!regex.test(pastedData)) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}

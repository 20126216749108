import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor(private socket: Socket) { }

  // sendMessage(msg: any){
  //   this.socket.emit('message', msg);
  // }
  //
  // getMessage(){
  //   return this.socket.fromEvent('message');
  // }
}

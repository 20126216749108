import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { AlertService } from './alert.service';
import { GlobalService } from './global.service';
import { Observable } from 'rxjs';
import { AppUrl } from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class NotifyService extends BaseService{

  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }
  get(data:{}): Observable<any>{
    return this.getRequest(AppUrl.NOTIFY(), data);
  }
  readNotification(): Observable<any>{
    return this.putRequest(AppUrl.READ_NOTIFICATION());
  }
  unreadNotificationCount(): Observable<any>{
    return this.getRequestWithoutErrorMessage(AppUrl.UNREAD_NOTIFICATION_COUNT());
  }
  unreadNotification(): Observable<any>{
    return this.getRequest(AppUrl.UNREAD_NOTIFICATION());
  }

}

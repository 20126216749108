import {
  Component,
  DestroyRef,
  ElementRef,
  HostBinding,
  inject,
  OnInit, TemplateRef, ViewChild
} from '@angular/core';
import {VexLayoutService} from '@vex/services/vex-layout.service';
import {VexConfigService} from '@vex/config/vex-config.service';
import {filter, map, startWith, switchMap} from 'rxjs/operators';
import {NavigationService} from '../../../core/navigation/navigation.service';
import {VexPopoverService} from '@vex/components/vex-popover/vex-popover.service';
import {MegaMenuComponent} from './mega-menu/mega-menu.component';
import {Observable, of} from 'rxjs';
import {NavigationComponent} from '../navigation/navigation.component';
import {ToolbarUserComponent} from './toolbar-user/toolbar-user.component';
import {ToolbarNotificationsComponent} from './toolbar-notifications/toolbar-notifications.component';
import {NavigationItemComponent} from '../navigation/navigation-item/navigation-item.component';
import {MatMenuModule} from '@angular/material/menu';
import {ActivatedRoute, NavigationEnd, Router, RouterLink} from '@angular/router';
import {AsyncPipe, NgClass, NgFor, NgIf, TitleCasePipe} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NavigationItem} from '../../../core/navigation/navigation-item.interface';
import {checkRouterChildsData} from '@vex/utils/check-router-childs-data';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AuthService} from "../../../shared/services/auth.service";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatCardHeader, MatCardTitle} from "@angular/material/card";
import {MatDialog, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatOption} from "@angular/material/autocomplete";
import {MatLabel, MatSelect} from "@angular/material/select";
import {SharedModule} from "../../../shared/shared.module";
//import {OrganisationService} from "../../../shared/services/organisation.service";
import {AlertService} from "../../../shared/services/alert.service";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    NgIf,
    RouterLink,
    MatMenuModule,
    NgClass,
    NgFor,
    NavigationItemComponent,
    ToolbarNotificationsComponent,
    ToolbarUserComponent,
    NavigationComponent,
    AsyncPipe,
    FormsModule,
    MatCardHeader,
    MatCardTitle,
    MatDialogContent,
    MatDialogTitle,
    MatOption,
    MatSelect,
    ReactiveFormsModule,
    SharedModule,
    TitleCasePipe,
    MatTooltip,
    MatLabel
  ]
})
export class ToolbarComponent implements OnInit {
  @ViewChild('planDetailModal') planDetailModal: TemplateRef<any>;
  @HostBinding('class.shadow-b')
  showShadow: boolean = false;
  user: any;
  finalPlans: any
  selectedPlanType: string;
  planForm: FormGroup;
  navigationItems$: Observable<NavigationItem[]> =
    this.navigationService.items$;

  isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.layout === 'horizontal')
  );
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.layout === 'vertical')
  );
  isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.navbar.position === 'in-toolbar')
  );
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.navbar.position === 'below-toolbar')
  );
  userVisible$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.toolbar.user.visible)
  );
  title$: Observable<string> = this.configService.select(
    (config) => config.sidenav.title
  );
  isDesktop$: Observable<boolean> = this.layoutService.isDesktop$;
  megaMenuOpen$: Observable<boolean> = of(false);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    private readonly layoutService: VexLayoutService,
    private readonly configService: VexConfigService,
    private readonly navigationService: NavigationService,
    private readonly popoverService: VexPopoverService,
    private readonly router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private alertService: AlertService
  ) {
    this.authService.self().subscribe(data => {
      if (data && data.id) {
        this.user = data;
        // if (this.user && (this.user['user_type'] === 'ORGANISATION' && this.user['use_status'] === 'NOT_PAID_NOT_ALLOWED')) {
        //   this.router.navigateByUrl('/upgrade-plan')
        // }
      } else {
        this.router.navigateByUrl('/');
      }
    });

  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(null),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.showShadow = checkRouterChildsData(
          this.router.routerState.root.snapshot,
          (data) => data.toolbarShadowEnabled ?? false
        );
      });
    this.planForm = this.fb.group({
      selectedPlan: ['Monthly Bliss', Validators.required]
    });
  }

  openQuickpanel(): void {
    this.layoutService.openQuickpanel();
  }

  openSidenav(): void {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement): void {
    this.megaMenuOpen$ = of(
      this.popoverService.open({
        content: MegaMenuComponent,
        origin,
        offsetY: 12,
        position: [
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
          }
        ]
      })
    ).pipe(
      switchMap((popoverRef) => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true)
    );
  }

  openPlanDetailModal(): void {
    this.dialog.open(this.planDetailModal, {
      width: '70vw',
      minWidth: '350px'
    });
  }

  onChange(type: any, SelectedPlan: any) {
    this.finalPlans.forEach((item: any) => {
      if (item.type == type) {
        item.plans.forEach(plan => {
          plan.status = plan.overview == SelectedPlan;
        });
        this.selectedPlanType = type;

      }
    });
  }

  getColorClass(overview: string): string {
    switch (overview) {
      case 'Monthly Bliss':
        return 'text-green';
      case 'Quarterly Advantage':
        return 'text-blue';
      case 'Half Yearly Triumph':
        return 'text-pink';
      case 'Yearly Excellence':
        return 'text-orange';
      default:
        return '';
    }
  }

  goToCheckOut(id: any): void {
    this.authService.self().subscribe(data => {
      if (data && data.id) {
        if (data) {
          this.router.navigate(['/checkout', id]);
        }
      }
    });
    this.router.navigate(['/']);
    this.dialog.closeAll()
  }

  openSearch(): void {
    this.layoutService.openSearch();
  }

  notAllowed() {
    this.alertService.showErrors('You have exceeded the user limit and cannot choose this plan.');
  }

  goTo(url) {
    this.router.navigateByUrl(url);
  }
}

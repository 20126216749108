import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SafePipe} from "./pipes/safe.pipe";
import {IndianNumberPipe} from "./pipes/indian-number.pipe";
import {NumberOnlyDirective} from "./directives/number-only.directive";

@NgModule({
  declarations: [
    SafePipe,
    IndianNumberPipe,
    NumberOnlyDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafePipe,
    IndianNumberPipe,
    NumberOnlyDirective
  ]
})
export class SharedModule {
}

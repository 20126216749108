import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { StorageService } from '../services/storage.service';

@Injectable()
export class AppUrl {

  static get ADMIN_URL(): string {
    return environment.appUrl + 'admin/';
  }

  static get STUDENT_URL(): string {
    return environment.appUrl + 'student/';
  }

  static get LOGIN(): string {
    return AppUrl.STUDENT_URL + 'login';
  }

  static get SELF(): string {
    return AppUrl.STUDENT_URL + 'self';
  }

  static UPDATE_PASSWORD(): string {
    return AppUrl.STUDENT_URL + 'update-password';
  }

  static UPDATE_SELF(): string {
    return AppUrl.ADMIN_URL + 'update-self';
  }

  static LOGOUT(): string {
    return AppUrl.STUDENT_URL + 'logout';
  }

  static UPDATE_PROFILE_IMAGE(): string {
    return AppUrl.ADMIN_URL + 'update-profile-image';
  }

  static FILE_UPLOAD(): string {
    return AppUrl.STUDENT_URL + 'file-upload';
  }

  static MULTIPLE_FILE_UPLOAD(): string {
    return AppUrl.ADMIN_URL + 'file-multiple-upload';
  }

  static COUNTRY(): string {
    return AppUrl.ADMIN_URL + 'countries';
  }

  static USERS(): string {
    return AppUrl.ADMIN_URL + 'user';
  }
  static CHAPTER(uniqueId?: any): string {

    if (uniqueId) {
      return AppUrl.STUDENT_URL + 'chapters/' + uniqueId;
    }
    else {
      return AppUrl.STUDENT_URL + 'chapters';
    }
  }

  static STATES(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.ADMIN_URL + 'states/' + uniqueId;
    else
      return AppUrl.ADMIN_URL + 'states';
  }

  static ADMIN_COUNTS(): string {
    return AppUrl.ADMIN_URL + 'admin-counts';
  }

  static ADMIN_WISE_ADMISSIONS(): string {
    return AppUrl.ADMIN_URL + 'admin-wise-admissions';
  }

  static ADMIN(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'admin/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'admin';
    }
  }

  static ADMIN_COUNT(): string {
    return AppUrl.ADMIN_URL + 'admin-count';
  }

  static ADMIN_STATUS(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'admin-status/' + unique_id;
  }

  static ADMIN_CHANGE_PASSWORD(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'admin-password/' + unique_id;
  }

  static PROGRAM(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.ADMIN_URL + 'program/' + uniqueId;
    else
      return AppUrl.ADMIN_URL + 'program';
  }

  static PROGRAM_COUNT(): string {
    return AppUrl.ADMIN_URL + 'program-count';
  }

  static PROGRAM_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'program-status/' + uniqueId;
  }

  static INVENTORY_CATEGORY(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.ADMIN_URL + 'inventory-category/' + uniqueId;
    else
      return AppUrl.ADMIN_URL + 'inventory-category';
  }

  static INVENTORY_CATEGORY_COUNT(): string {
    return AppUrl.ADMIN_URL + 'inventory-category-count';
  }

  static INVENTORY_CATEGORY_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'inventory-category-status/' + uniqueId;
  }

  static INVENTORY(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.ADMIN_URL + 'inventory/' + uniqueId;
    else
      return AppUrl.ADMIN_URL + 'inventory';
  }

  static INVENTORY_COUNT(): string {
    return AppUrl.ADMIN_URL + 'inventory-count';
  }

  static INVENTORY_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'inventory-status/' + uniqueId;
  }

  static INVENTORY_STOCK(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.ADMIN_URL + 'inventory-stock/' + uniqueId;
    else
      return AppUrl.ADMIN_URL + 'inventory-stock';
  }

  static INVENTORY_STOCK_COUNT(): string {
    return AppUrl.ADMIN_URL + 'inventory-stock-count';
  }

  static INVENTORY_STOCK_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'inventory-stock-status/' + uniqueId;
  }

  static INVENTORY_STOCK_INVOICE_ID(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'inventory-stock-invoice-id/' + uniqueId;
  }

  static COURSE(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.ADMIN_URL + 'course/' + uniqueId;
    else
      return AppUrl.ADMIN_URL + 'course';
  }

  static COURSE_COUNT(): string {
    return AppUrl.ADMIN_URL + 'course-count';
  }

  static COURSE_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'course-status/' + uniqueId;
  }

  static BRANCH(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.ADMIN_URL + 'branch/' + uniqueId;
    else
      return AppUrl.ADMIN_URL + 'branch';
  }

  static DOCS(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.STUDENT_URL + 'student-docs/' + uniqueId;
    else
      return AppUrl.STUDENT_URL + 'student-docs';
  }
  static SUPPORT_TICKETS(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.STUDENT_URL + 'support-ticket/' + uniqueId;
    else
      return AppUrl.STUDENT_URL + 'support-ticket';
  }

  static BRANCH_COUNT(): string {
    return AppUrl.ADMIN_URL + 'branch-count';
  }

  static BRANCH_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'branch-status/' + uniqueId;
  }

  static SESSION(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.STUDENT_URL + 'session/' + uniqueId;
    else
      return AppUrl.STUDENT_URL + 'session';
  }

  static DEFAULT_SESSION(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'default-session/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'default-session';
    }
  }

  static SESSION_COUNT(): string {
    return AppUrl.ADMIN_URL + 'session-count';
  }

  static SESSION_STATUS(uniqueId: any, status: any): string {
    if (status == 'default_session')
      return AppUrl.ADMIN_URL + 'default-session';
    else if (status == 're-registration-session')
      return AppUrl.ADMIN_URL + 're-registration-session/' + uniqueId;
    else if (status == 're-registration-entry-session')
      return AppUrl.ADMIN_URL + 're-registration-entry-session/' + uniqueId;
    else if (status == 'admission-session')
      return AppUrl.ADMIN_URL + 'admission-session/' + uniqueId;
    else if (status == 're-opened-session')
      return AppUrl.ADMIN_URL + 're-opened-session/' + uniqueId;
    else
      return AppUrl.ADMIN_URL + 'session-status/' + uniqueId;
  }

  static PCB_MAPPING(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.STUDENT_URL + 'pcb-mapping/' + uniqueId;
    else
      return AppUrl.STUDENT_URL + 'pcb-mapping';
  }

  static PCB_MAPPING_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'pcb-mapping-status/' + uniqueId;
  }

  static PCBS_MAPPING(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.STUDENT_URL + 'pcbs-mapping/' + uniqueId;
    else
      return AppUrl.STUDENT_URL + 'pcbs-mapping';
  }

  static PCBS_BY_PCB_ID(pcbId): string {
    return AppUrl.STUDENT_URL + 'pcbs-mapping-by-pcb/' + pcbId;
  }

  static PCBS_MAPPING_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'pcbs-mapping-status/' + uniqueId;
  }

  static FEE_STRUCTURE(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'fee-structure/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'fee-structure';
    }
  }

  static FEE_STRUCTURE_PCB(): string {
    return AppUrl.ADMIN_URL + 'fee-structure-by-pcb';
  }

  static SUBJECT(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.STUDENT_URL + 'subject/' + uniqueId;
    else
      return AppUrl.STUDENT_URL + 'subject';
  }

  static SUBJECT_COUNT(): string {
    return AppUrl.ADMIN_URL + 'subject-count';
  }

  static SUBJECT_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'subject-status/' + uniqueId;
  }

  static STUDENT(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.STUDENT_URL + 'student/' + uniqueId;
    } else {
      return AppUrl.STUDENT_URL + 'student';
    }
  }

  static HOLIDAY():string {
    return AppUrl.STUDENT_URL + 'holiday';
  }

  static STUDENT_ATTANDENCE(): string {
    return AppUrl.STUDENT_URL + 'student-attendance';
  }

  static STUDENT_ISSUED_BOOK(): string {
    return AppUrl.STUDENT_URL + 'library';
  }

  static STUDENT_TRANSACTION(): string {
    return AppUrl.STUDENT_URL + 'student-transaction';
  }

  static STUDENT_LIBRARY_TRANSACTION(): string {
    return AppUrl.STUDENT_URL + 'library-transaction';
  }

  static STUDENT_COUNT(): string {
    return AppUrl.ADMIN_URL + 'student-count';
  }

  static STUDENT_PERSONAL_INFO(uniqueId: any): string {
    return AppUrl.STUDENT_URL + 'student-personal-info/' + uniqueId;
  }

  static UPLOAD_ASSIGNMENT(): string{
    return AppUrl.STUDENT_URL + 'assignment';
  }

  static STUDENT_PHOTOGRAPH(uniqueId: any): string {
    return AppUrl.STUDENT_URL + 'student-personal-info-photograph/' + uniqueId;
  }

  static STUDENT_GOVT_PHOTO_ID(uniqueId: any): string {
    return AppUrl.STUDENT_URL + 'student-personal-info-govt-photo-id-proof/' + uniqueId;
  }

  static STUDENT_SIGNATURE(uniqueId: any): string {
    return AppUrl.STUDENT_URL + 'student-personal-info-student-signature/' + uniqueId;
  }

  static STUDENT_ADDRESS_INFO(uniqueId: any): string {
    return AppUrl.STUDENT_URL + 'student-address/' + uniqueId;
  }

  static STUDENT_ACADEMIC_DETAIL(uniqueId: any): string {
    return AppUrl.STUDENT_URL + 'academic-details/' + uniqueId;
  }

  static STUDENT_TENTH_CERTIFICATE(uniqueId: any): string {
    return AppUrl.STUDENT_URL + 'academic-details-tenth-certificate/' + uniqueId;
  }

  static STUDENT_TWELTH_CERTIFICATE(uniqueId: any): string {
    return AppUrl.STUDENT_URL + 'academic-details-twelth-certificate/' + uniqueId;
  }

  static STUDENT_MORE_QUALIFICATION(uniqueId: any): string {
    return AppUrl.STUDENT_URL + 'more-qualification/' + uniqueId;
  }

  static STUDENT_PREVIOUS_CERTIFICATE(uniqueId: any): string {
    return AppUrl.STUDENT_URL + 'more-qualification-previous-certificate/' + uniqueId;
  }

  static STUDENT_GRADUATION_CERTIFICATE(uniqueId: any): string {
    return AppUrl.STUDENT_URL + 'more-qualification-graduation-certificate/' + uniqueId;
  }

  static STUDENT_POST_GRADUATION_CERTIFICATE(uniqueId: any): string {
    return AppUrl.STUDENT_URL + 'more-qualification-post-graduation-certificate/' + uniqueId;
  }

  static STUDENT_OTHER_QUALIFICATION_CERTIFICATE(uniqueId: any): string {
    return AppUrl.STUDENT_URL + 'more-qualification-other-qualification-certificate/' + uniqueId;
  }

  static STUDENT_COURSE_DETAIL(uniqueId: any): string {
    return AppUrl.STUDENT_URL + 'course-details/' + uniqueId;
  }


  static SUBJECT_SLM_ID(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'subject-slm/' + uniqueId;
  }

  static SUBJECT_NOTE_ID(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'subject-note/' + uniqueId;
  }
  static  NOTIFY(): string {
    return AppUrl.STUDENT_URL + 'notification';
  }
  static READ_NOTIFICATION(): string {
    return AppUrl.STUDENT_URL + 'read-notification';
  }
  static UNREAD_NOTIFICATION_COUNT(): string {
    return AppUrl.STUDENT_URL + 'unread-notification-count';
  }
  static UNREAD_NOTIFICATION(): string {
    return AppUrl.STUDENT_URL + 'unread-notification';
  }
}


<div
  [class.container]="isVerticalLayout$ | async"
  class="toolbar bg-white text-default w-full px-6 flex items-center opacity-8">
  <button
    (click)="openSidenav()"
    [class.hidden]="isDesktop$ | async"
    mat-icon-button
    type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>
  <a
    *ngIf="isVerticalLayout$ | async"
    [routerLink]="['/']"
    class="ltr:mr-4 rtl:ml-4 flex items-center">
    <img
      alt="Logo"
      class="w-8 select-none"
      src="assets/img/logo/logo-arni.png"/>
    <h1
      [class.hidden]="isDesktop$ | async"
      class="text-2xl font-bold tracking-wide ltr:pl-4 rtl:pr-4 m-0 select-none">
      {{ title$ | async }}
    </h1>
  </a>
  <mat-menu
    #addNewMenu="matMenu"
    [overlapTrigger]="false"
    xPosition="after"
    yPosition="below">
    <button mat-menu-item>
      <mat-icon svgIcon="mat:person_add"></mat-icon>
      <span>Add Contact</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:done_all"></mat-icon>
      <span>Add Task</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:assignment_turned_in"></mat-icon>
      <span>Add Project</span>
    </button>

    <button [matMenuTriggerFor]="documentMenu" mat-menu-item>
      <mat-icon svgIcon="mat:ballot"></mat-icon>
      <span>Add Document</span>
    </button>
  </mat-menu>

  <mat-menu #documentMenu="matMenu">
    <button mat-menu-item>
      <mat-icon svgIcon="mat:description"></mat-icon>
      <span>Add Quote</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:assignment"></mat-icon>
      <span>Add Invoice</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:receipt"></mat-icon>
      <span>Add Receipt</span>
    </button>
  </mat-menu>

  <div
    *ngIf="(isVerticalLayout$ | async) && (isNavbarInToolbar$ | async)"
    [class.hidden]="!(isDesktop$ | async)"
    class="px-6 flex-none flex items-center">
    <vex-navigation-item
      *ngFor="let item of navigationItems$ | async"
      [item]="item"></vex-navigation-item>
  </div>

  <span class="flex-1"></span>

  <div class="-mx-1 flex items-center">
    <div class="px-1">
      <vex-toolbar-notifications></vex-toolbar-notifications>
    </div>

    <div *ngIf="userVisible$ | async" class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>

    <mat-menu
      #languageMenu="matMenu"
      overlapTrigger="false"
      xPosition="before"
      yPosition="below">
      <button mat-menu-item>
        <mat-icon svgIcon="flag:united-states"></mat-icon>
        <span>English</span>
      </button>

      <button mat-menu-item>
        <mat-icon svgIcon="flag:germany"></mat-icon>
        <span>German</span>
      </button>
    </mat-menu>
  </div>
</div>

<vex-navigation
  *ngIf="(isVerticalLayout$ | async) && (isNavbarBelowToolbar$ | async)"
  [class.hidden]="!(isDesktop$ | async)"></vex-navigation>

<ng-template #planDetailModal>
  <mat-card-header class="m-b-20px subheading-2 table-header-color p-2">
    <mat-card-title mat-dialog-title>
        <span class="subheading-2 text-xl text-white font-bold"
              [style.font-family]="'var(--vex-font)'">Choose a plan</span>
      <i class="fa fa-window-close text-white text-xl float-right text-bold cursor-pointer"
         (click)="dialog.closeAll()"></i>
    </mat-card-title>
  </mat-card-header>
  <div mat-dialog-content>
    <div class="flex flex-wrap py-3 gap-4" [style.font-family]="'var(--vex-font)'">
      <div class="p-8 w-full border md:w-[32%] border-gray-300 relative rounded-2xl py-14"
           *ngFor="let planType of finalPlans; let i = index"
           [ngClass]="{'bg-light-purple text-dark-purple': selectedPlanType === planType.type, 'bg-white': selectedPlanType !== planType.type}">
        <div *ngIf="user?.plan_id === planType.plan_id"
             class="bg-green-600 mt-4 px-3 md:px-8 py-1 font-bold left text-white rounded-3xl absolute whitespace-nowrap"
             [style.font-family]="'var(--vex-font)'" style="left: 63%; top:-8%; transform: translateX(-100%);">
          Current
        </div>
        <div class="absolute top-2 right-2">
          <div *ngIf="planType.plans">
            <div *ngFor="let plan of planType.plans;">
              <div class="text-gray-700" *ngIf="plan.status == true;">
                <i [ngClass]="[plan.icon_class, getColorClass(plan.overview)]"></i>
                {{ plan.icon_text }}
              </div>
            </div>
          </div>
        </div>
        <h2 class="text-3xl font-bold mb-4 plan-name">{{ planType.type | titlecase }} Plan</h2>
        <form [formGroup]="planForm" class="pt-6 pb-1" [style.font-family]="'var(--vex-font)'">
          <mat-label class="text-[11px] absolute font-semibold text-gray-500" style="top:25%; left: 12%">Select Plan
            Duration
          </mat-label>
          <mat-select class="mx-auto pb-10 pt-4 rounded-md toolbar-select-option subheading-2 ps-4 ellipsis-text" #event
                      [style.font-family]="'var(--vex-font)'"
                      (selectionChange)="onChange(planType.type, event.value)"
                      formControlName="selectedPlan" placeholder="Select plan duration">
            <mat-option *ngFor="let plan of planType.plans" [value]="plan.overview">
              {{ plan.overview }}
            </mat-option>
          </mat-select>
        </form>

        <div *ngIf="planType.plans" [style.font-family]="'var(--vex-font)'">
          <div *ngFor="let plan of planType.plans;">
            <div *ngIf="plan.status">

              <p class="mt-3">{{ plan.description }}</p>
              <p class="mt-1 text-xl"><strong class="text-[16px] text-gray-600">Users: </strong>{{ plan.users }}</p>
              <p class="mt-1 text-xl"><strong class="text-[16px] text-gray-600">Price: </strong><span
                class="line-through text-red-500">&#8377;{{ plan.price | IndianNumber }}/-</span></p>
              <p class="mt-1 text-xl"><strong class="text-[16px] text-gray-600">Discounted Price: </strong><span
                class="text-green-600">&#8377;{{ plan.discounted_price | IndianNumber }}/-</span></p>
              <button *ngIf="(user && (user.user_count <= plan.users)) || !user"
                      class="w-full primary-btn mt-3 p-4 rounded-2xl"
                      (click)="goToCheckOut(plan['plan_id'])">
                Pay Now
              </button>
              <button *ngIf="user && (user.user_count > plan.users)"
                      class="w-full primary-btn mt-3 p-4 rounded-2xl cursor-not-allowed opacity-8"
                      matTooltip="You are not allowed to choose this plan" (click)="notAllowed()">
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

import {Injectable} from '@angular/core';
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "./alert.service";
import {GlobalService} from "./global.service";
import {Observable} from "rxjs";
import {AppUrl} from "../constants/app-url";

@Injectable({
  providedIn: 'root'
})
export class SessionService extends BaseService {

  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService)
  }

  add(data: {}): Observable<any> {
    return this.postRequest(AppUrl.SESSION(), data);
  }

  get(data: {}): Observable<any> {
    return this.getRequest(AppUrl.SESSION(), data);
  }


  count(data: {}): Observable<any> {
    return this.getRequest(AppUrl.SESSION_COUNT(), data);
  }

  detail(uniqueId: any): Observable<any> {
    return this.getRequest(AppUrl.SESSION(uniqueId));
  }

  update(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.SESSION(uniqueId), data);
  }

  status(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.SESSION_STATUS(uniqueId, "status"), data);
  }

  default_session(): Observable<any> {
    return this.getRequest(AppUrl.SESSION_STATUS("", "default_session"));
  }

  re_registration_session(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.SESSION_STATUS(uniqueId, "re-registration-session"), data);
  }

  re_registration_entry_session(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.SESSION_STATUS(uniqueId, "re-registration-entry-session"), data);
  }

  admissionSession(uniqueId: any, data: {}): Observable<any> {
    return this.putRequest(AppUrl.SESSION_STATUS(uniqueId, "admission-session"), data);
  }

  delete(uniqueId: any): Observable<any> {
    return this.deleteRequest(AppUrl.SESSION(uniqueId));
  }

  markAsDefaultSession(uniqueId: any, data): Observable<any> {
    return this.putRequest(AppUrl.DEFAULT_SESSION(uniqueId), data);
  }
}

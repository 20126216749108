import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterLink} from '@angular/router';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  standalone: true,
  styleUrls: ['./header.component.scss'],
  imports: [
    MatToolbarModule,
    MatButtonModule,
    RouterLink,
    MatIconModule,
    CommonModule
  ]
})
export class HeaderComponent implements OnInit {
  isMenuOpen: any;
  loginStatus: any;
  currentYear: any;

  constructor(private router: Router) {
    this.loginStatus = localStorage.getItem('self');
  }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
// this.toggleMenu()
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    // console.log(this.isMenuOpen)

  }

  goTo(url) {
    this.router.navigateByUrl(url);
  }

  openUrl(url: any) {
    window.open(url, '_blank');
  }


}

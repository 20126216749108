import { Injectable } from '@angular/core';
import Pusher, { Channel } from 'pusher-js';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  pusher: Pusher;

  constructor() {
    this.pusher = new Pusher('ca957f75398e83123fc2', {cluster: 'ap2'});
  }

  subscribe(channelName: string, eventName: string, callback: Function): void {
    const channel: Channel = this.pusher.subscribe(channelName);
    channel.bind(eventName, callback);
  }
}

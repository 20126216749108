import {Routes} from '@angular/router';

export const content: Routes = [
  {
    path: 'home',
    loadChildren: () => import('../../layouts/components/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'general/application',
    loadChildren: () => import('../../layouts/components/application-form/application-form.module').then(m => m.ApplicationFormModule)
  },
  {
    path: 'general/attendance',
    loadChildren: () => import('../../layouts/components/attendance/attendance.module').then(m => m.AttendanceModule)
  },
  {
    path: 'general/accounts',
    loadChildren: () => import('../../layouts/components/accounts/accounts.module').then(m => m.AccountsModule)
  },
  {
    path: 'docs',
    loadChildren: () => import('../../layouts/components/docs/docs.module').then(m => m.DocsModule)
  },
  {
    path: 'lms',
    loadChildren: () => import('../../layouts/components/lms/lms.module').then(m => m.LmsModule)
  },
  {
    path: 'exam',
    loadChildren: () => import('../../layouts/components/exam/exam.module').then(m => m.ExamModule)
  },
  {
    path: 'utilities',
    loadChildren: () => import('../../layouts/components/utilities/utilities.module').then(m => m.UtilitiesModule)
  },
  {
    path: 'utilities/library',
    loadChildren: () => import('../../layouts/components/utilities/library/library.module').then(m => m.LibraryModule)
  },
  {
    path: 'notification/list',
    loadChildren: () => import('../../layouts/components/notification/notification.module').then(m => m.NotificationModule)
  },
  {
    path: 'support',
    loadChildren: () => import('../../layouts/components/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('../../layouts/components/profile/profile.module').then(m => m.ProfileModule)
  }
  /*{
    path: 'apps',
    loadChildren: () => import('../../layouts/components/app/app.module').then(m => m.AppModule)
  },*/
];

export const landing: Routes = [
  {
    path: 'landing',
    loadChildren: () => import('../../landing/pages-list/Landing/Landing.module').then(m => m.LandingModule)
  }
];

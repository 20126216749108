import {Component} from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet]
})
export class AppComponent {
  constructor(private router: Router) {
    /*const message = "STOP!!";
    console.log(`%c${message}`, 'color: red; font-size: 48px; font-weight: bold; padding: 5px; border-radius: 3px;');
    console.log('Beware of scams! This is a browser feature intended for developers. If someone instructs you to copy and paste any code or text here to activate any "CMS" feature, do not comply. This is a scam designed to steal your credentials and compromise your CMS' +
      ' account. Stay vigilant and keep your account secure!')
    console.warn = () => {}
    console.error = () => {}
    console.log = () => {}
    console.info = () => {}
    console.debug = () => {}*/

    // for screollbar on top
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const contentContainer = document.querySelector('.mat-sidenav-content') || document.body;
        contentContainer.scrollTop = 0;
      }
    });
  }
}

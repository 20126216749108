<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <ng-template #imageUpdateDialog>
      <mat-card-header class="m-b-20px subheading-2 purple-color p-2">
        <mat-card-title mat-dialog-title>
          <span class="subheading-2 text-xl white-color" [style.font-family]="'var(--vex-font)'"><i
            class="fa fa-upload text-xl"></i>  Update Profile Picture</span>
          <i class="fa fa-window-close color-white text-xl float-right text-bold cursor-pointer"
             (click)="dialog.closeAll()"></i>
        </mat-card-title>
      </mat-card-header>
      <div mat-dialog-content>
        <div class="text-l" [style.font-family]="'var(--vex-font)'">
          <div class="flex flex-wrap justify-content-center items-center p-2">
            <div class="mx-auto">
              <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"
              ></image-cropper>
            </div>
            <div
              id="file-upload-box"
              class="border-2 border-purple p-4 w-full mt-4 text-center cursor-pointer"
              (dragover)="onDragOver($event)"
              (drop)="onDrop($event)"
              (click)="fileInput.click()"
            >
              <input
                #fileInput
                type="file"
                class="file-input hidden"
                (change)="getImage($event)"
              />
              <div class="flex justify-content-center item-center">
                <img src="/assets/profile-logos/file-upload1.png" alt="Upload File"/>
              </div>
              <p class="text-gray-600 pt-2" [style.font-family]="'var(--vex-font)'">Click here to browse</p>
            </div>
          </div>
          <div class="flex flex-wrap justify-content-center items-center p-2">
            <h2>or</h2>
          </div>
          <div class="text-center">
            <h2 class="text-l font-bold" [style.font-family]="'var(--vex-font)'">Choose from our cool avatars</h2>
          </div>
          <div class="icon-grid p-2">
            <div class="icon-cell" *ngFor="let icon of profileIcons">
            </div>
          </div>
        </div>
      </div>
      <div class="p-15px flex justify-between purple-color">
        <button mat-raised-button class="cursor-pointer"
                [style.font-family]="'var(--vex-font)'"
                (click)="dialog.closeAll()">
          Cancel
        </button>

        <button *ngIf="imageChangedEvent" mat-raised-button class="cursor-pointer"
                [style.font-family]="'var(--vex-font)'"
                (click)="fileChangeEvent(croppedImage, 'PROFILE_IMAGE')">
          Save
        </button>
      </div>
    </ng-template>


    <div class="flex items-center relative">
      <div class="dropdown-heading-icon flex items-center justify-center">
        <img [src]="resultImage" class="avatar-image" alt="Profile" *ngIf="resultImage">
        <i class="fa-solid fa-circle-user f-70px" title="Profile" *ngIf="!resultImage"></i>
        <mat-icon class="edit-icon" svgIcon="mat:edit" (click)="openDialog()"
                  *ngIf="user && (user.user_type == 'ORGANISATION')"
        ></mat-icon>
      </div>
      <div class="dropdown-heading text-ellipsis">{{user?.name}}</div>
    </div>

  </div>

  <div class="dropdown-content">
    <a
      (click)="close()"
      *ngFor="let item of items; let i = index; trackBy: trackById"
      [routerLink]="item.route"
      class="notification flex items-center"
      [ngClass]="user?.user_type !== 'ORGANISATION' && i === 1 ? 'hidden' : ''"
      matRipple>
      <mat-icon
        [ngClass]="item.colorClass"
        [svgIcon]="item.icon"
        class="notification-icon flex-none"></mat-icon>
      <div class="flex-auto">
        <div class="notification-label">{{ item.label }}</div>
        <div class="notification-description">{{ item.description }}</div>
      </div>
      <mat-icon
        class="notification-chevron flex-none"
        svgIcon="mat:chevron_right"></mat-icon>
    </a>
  </div>

  <div class="dropdown-footer flex items-center  justify-center">
    <a (click)="logout()" [routerLink]="['/login']" color="primary" mat-button>Logout</a>
  </div>
</div>

<mat-menu #statusMenu="matMenu" xPosition="before" yPosition="below">
  <button
    (click)="setStatus(status)"
    *ngFor="let status of statuses; trackBy: trackById"
    mat-menu-item>
    <mat-icon [ngClass]="status.colorClass" [svgIcon]="status.icon"></mat-icon>
    <span>{{ status.label }}</span>
  </button>
</mat-menu>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <!--<button mat-menu-item>
    <mat-icon svgIcon="mat:business"></mat-icon>
    <span>Change Your Profile Picture</span>
  </button>
-->
  <button mat-menu-item>
    <mat-icon svgIcon="mat:business"></mat-icon>
    <span>Change Address</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:verified_user"></mat-icon>
    <span>Change Username</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:lock"></mat-icon>
    <span>Change Password</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:notifications_off"></mat-icon>
    <span>Disable Notifications</span>
  </button>
</mat-menu>


